<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Station Fault Transaction Details
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
          <asom-form-field label="Fault Number">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "faultNo") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Notification ID/ Trag No / OCC No.">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "notificationId") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Work Order Id">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "workOrderId") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Work Order Status">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "workOrderStatus") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Has maintenance staff been informed?">
            <p class="font-semibold">
              {{ get(itemData, "isMaintainanceStaffInformed") ? "YES" : "NO" }}
            </p>
          </asom-form-field>
          <asom-form-field label="Work Centre / Section">
            <p class="font-semibold">
              {{ get(itemData, "workCenter") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Functional Location">
            <p class="font-semibold">
              {{
                getObjectValue(itemData, "momsNotification_FunctionalLocation")
              }}
            </p>
          </asom-form-field>
          <asom-form-field label="Planner Group">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "momsNotification_PlannerGroup") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Description" class="col-span-2">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "description") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Remarks" class="col-span-2">
            <p class="font-semibold">
              {{ getObjectValue(itemData, "remarks") }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="canShowFiles"
            label="Supporting Document"
          >
            <asom-upload-file-list :files="itemData.files" disabled-remove />
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { getStationFaultTransactionDetails } from "../../../services/faultManagement.service";
import { getObjectValue } from "../../../helpers/getObjectValue";

export default {
  name: "ViewOrder",
  data() {
    return {
      selectedItem: null,
      itemData: {
        faultNo: get(this.$route.params, "faultNo", null),
        description: get(this.$route.params, "description", null),
        remarks: get(this.$route.params, "remarks", null),
        tragNo: get(this.$route.params, "tragNo", null),
        isMaintainanceStaffInformed: get(
          this.$route.params,
          "isMaintainanceStaffInformed",
          null
        ),
        workCenter: get(this.$route.params, "workCenter", null),
        workOrderId: get(this.$route.params, "workOrderId", null),
        workOrderStatus: get(this.$route.params, "workOrderStatus", null),
        notificationId: get(this.$route.params, "notificationId", null),
        functionalLocation: get(this.$route.params, "momsNotification_FunctionalLocation", null),
        plannerGroup: get(this.$route.params, "momsNotification_PlannerGroup", null),
        files: [],
      },
    };
  },
  computed: {
    canShowFiles() {
      if (this.itemData.files !== null) {
        return this.itemData.files.length > 0;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.getFaultDetails();
  },
  methods: {
    get,
    getObjectValue,
    async getFaultDetails() {
      const result = await getStationFaultTransactionDetails({
        transactionId: get(this.$route.params, "transactionId"),
      });
      if (result.success) {
        this.selectedItem = get(result.payload, "fault", {});
        this.setFormData(get(result.payload, "fault", {}));
      }
    },
    setFormData(data) {
      this.itemData.faultNo = get(data, "faultNo", "-");
      this.itemData.description = get(data, "description", "-");
      this.itemData.remarks = get(data, "remarks", "-");
      this.itemData.tragNo = get(data, "tragNo", "-");
      this.itemData.isMaintainanceStaffInformed = get(
        data,
        "isMaintainanceStaffInformed",
        "-"
      );
      this.itemData.workCenter = get(data, "workCenter", "-");
      
      this.itemData.functionalLocation = get(this.$route.params, "momsNotification_FunctionalLocation", '-'),
      this.itemDataplannerGroup = get(this.$route.params, "momsNotification_PlannerGroup", '-'),
      this.itemData.notificationId = get(
        this.$route.params,
        "notificationId",
        "-"
      );
      this.itemData.workOrderId = get(this.$route.params, "workOrderId", "-");
      this.itemData.workOrderStatus = get(
        this.$route.params,
        "workOrderStatus",
        "-"
      );
      let attachments = get(data, "attachments", null);
      if (attachments !== null && attachments.length > 0)
        this.itemData.files = attachments;
    },
  },
};
</script>
